import { GET_CARD_BALANCE, UNSET_CARD_BALANCE } from "../../../../enums/Common/Common";

export const getMealCardBalanceAction = (data) => {
    return {
        type: GET_CARD_BALANCE,
        payload: data,
    };
};
export const unsetMealCardBalanceAction = () => {
    return {
        type: UNSET_CARD_BALANCE,
    };
};
