/**
 * HOC to call recursively request validation stages.
 * @constructor
 * @typedef {object} ValidationTupleIndex0 - an object with custom number of propreties accessible in the Component.
 * @typedef {JSX.Element} ValidationTupleIndex1 - React Component should be called in a following step.
 * @typedef {[ValidationTupleIndex0, ValidationTupleIndex0]} ValidationTuple
 * @param {ValidationTuple} tuple - Array of tuples.
 */

const StageValidationHOC = (tuple) => {
    if (!tuple.length) {
        return null;
    }
    const temp = [...tuple];
    const step = temp.shift();
    // step[0].next()
    const [actions, Component] = step;
    // console.log("Component:::::", Component);

    actions.newTuple = temp;

    // console.log("new Tuple: ", actions.newTuple);

    return (
        // FIX NAMING (THIS INST HANDLER)
        <Component {...actions} />
    );
};

export default StageValidationHOC;
