import { memo } from "react";

import "./RoundedRemoveItemButton.scss";

const RoundedRemoveItemButton = (
    {
        handler, index,
    },
) => {
    // const log = handler(index);
    // console.log(log);
    return <div
        className="rounded-remove-item-button"
        onClick={(event) => {
            event.preventDefault();
            handler(index);
        }}>

        {""}
    </div>;
};

export default memo(RoundedRemoveItemButton);
