import { isTerminal } from "../../../constants";
import WidgetDiv from "../WidgetDiv/WidgetDiv";
import { BackArrowButton } from "../componentsTerminal/BackArrowButton/BackArrowButton";

isTerminal ? import("./MultiWidgetTerminal.scss") : import("./MultiWidget.scss");


const MultiWidget = (
    {
        styleParent,
        subHeader,
        isBackButtonEnabled,
        data,
    }) => {
    return (
        <>
            {isTerminal && isBackButtonEnabled
                ? (
                    <div className="multi-wdget__back-button">
                        <BackArrowButton />
                    </div>
                )
                : null
            }
            {
                subHeader &&
                <h3 className="widgets-header">{subHeader}</h3>
            }
            {data && data.map((e, i) => (

                <WidgetDiv
                    key={i}
                    title={e.title}
                    description={e.description}
                    styleParent={styleParent} />
            ))}
        </>
    );
};

export default MultiWidget;
