import { $api } from "../../../../services/request.config";
import { sendMemoThunkAction } from "../../RequestsFromLeader/RecruitmentToWork/thunk/sendMemo.thunk.action";

export const verifyPasswordThunkAction = (data) => async (dispatch) => {
    const { password, hoc, setPassword, requestCard } = data;
    console.log(hoc.props);
    try {
        const response = await $api.post("v1/auth/verify/password", { password: password.password });
        if (response.status === 204) {
            console.log("WWOORK");
            setPassword((prev) => {
                return {
                    ...prev,
                    sendButtonLoading: false,
                    // correctPassword: true,
                };
            });
            hoc.next(hoc.newTuple, hoc.props);
            // const response = await $api.post(`/v1/managers/requests/rvd/applications/${requestCard.uid}/memo`, { pids: hoc.props.pid });

            dispatch(sendMemoThunkAction({ uid: requestCard.uid, employees: hoc.props.pids, hoc }));

        }

    } catch (e) {
        // setPassword((prev) => {
        //     return {
        //         ...prev,
        //         sendButtonLoading: false,
        //         correctPassword: false,
        //     };
        // });
    }

};
