import ModalBanOverlay from "../../../../Modal/ModalBanOverlay/ModalBanOverlay";
import OkButton from "../../../../Button/OKButton/OKButton";

import BasicInput from "../../../../Inputs/BasicInput/BasicInput";
import { isTerminal } from "../../../../../../constants";

import CustomButton from "../../../../Button/CustomButton/CustomButton";

import {
    sendMemoThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/RecruitmentToWork/thunk/sendMemo.thunk.action";

import {
    verifyPasswordThunkAction,
} from "../../../../../../redux/actions/Requests/CurrentRequest/thunk/verifyPassword.thunk.action";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function ApproveRequest(
    { props, back, next, newTuple },
) {
    const dispatch = useDispatch();
    const requestCard = useSelector((state) => state.requests?.singleDocument?.currentRequest);

    const [password, setPassword] = useState({
        password: "",
        correctPassword: true,
        stateLoader: false,
        sendButtonLoading: false,
    });

    function checkPasswordHandler() {
        setPassword((prev) => {
            return { ...prev, sendButtonLoading: true };
        });
        const data = {
            password: password,
            hoc: {
                next: next,
                newTuple: newTuple,
                props: props,
            },
            setPassword: setPassword,
            requestCard: requestCard,
        };
        dispatch(verifyPasswordThunkAction(data));
        // next(newTuple, props);
    }

    console.log("newTuple: ", password);
    return (
        <ModalBanOverlay shown={true}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                background: "#FFFFFF",
                minHeight: "40vh",
                width: "70vw",
                textAlign: "center",
                padding: "20px",
                gap: "30px",
            }}>
                <div className="request-verification__head">
                    Для подписания введите пароль от вашего личного кабинета
                </div>
                <div
                    onFocus={() => setPassword((prev) => {
                        return {
                            ...prev, correctPassword: true,
                        };
                    })}
                    className="request-verification__input">
                    <BasicInput
                        eye={true}
                        passedState={password.password}
                        correctPassword={password.correctPassword}
                        stateType={"password"}
                        stateHandler={setPassword}
                        type={"password"}
                        placeholder={"Введите пароль"}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "800px",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}

                    className="request-verification__button">
                    <OkButton
                        // onValidation={password.sendButtonLoading}
                        handler={() => back()}
                        title={"отмена"} />
                    <OkButton
                        handler={() => {
                            checkPasswordHandler();

                        }}
                        onValidation={password.sendButtonLoading}
                        // handler={buttonHandler}
                        title={"OK"} />
                </div>
            </div>

        </ModalBanOverlay>
    );
}

export default ApproveRequest;
