import RoundedAddItemButton from "../../UI/RoundedAddItemButton/RoundedAddItemButton";
import RoundedRemoveItemButton from "../../UI/RoundedRemoveItemButton/RoundedRemoveItemButton";

import {
    addObjKeyYoArrayAction,
} from "../../../redux/actions/Requests/currentRequestControls/addObjKeyYoArray.action";

import {
    removeObjfromArrayAction,
} from "../../../redux/actions/Requests/currentRequestControls/removeObjfromArray.action";


import DayItem from "../../pages/HRServicesPage/LeaderRequestsPage/LRPage/RecruitmentToWorkOnHolidays/DayItem/DayItem";

import { inputValidationInArray } from "../../../redux/actions/Requests/currentRequestControls/inputValidationInArray";

import loginPage from "../../pages/LoginPage/LoginPage";

import React, { useCallback, useState, forwardRef, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { v4 as uuid } from "uuid";
import moment from "moment";
import ru from "date-fns/locale/ru";

import "./DateSelectorList.scss";

import { useDispatch, useSelector } from "react-redux";


const AddBox = forwardRef(({ onClick, ...props }, ref) => {
    return (
        <div
            onClick={onClick}
            ref={ref}>
            <RoundedAddItemButton {...props} />
        </div>
    );
});

const ItemBox = ({ value }) => {
    return (
        <div
            className="date-selector-list__item"
        >
            <div>{value ? value : "Выберите дату"}</div>
            <div
                className="date-selector-list__item__buffer"></div>
        </div>
    );
};

const DateSelectorList = (
    {
        element,
        elementIndex,
        parentStyle,
        isValidKey,
    },
) => {
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(0);

    const addItemHandler = useCallback((date) => {
        dispatch(addObjKeyYoArrayAction({
            arrayKey: "days",
            objKeyName: "jobDate",
            objKey: moment(date).format("YYYY-MM-DD"),
            indexInArray: element.days.length,
            elementIndex: elementIndex,
            fieldArray: "employees",
        }));
        dispatch(addObjKeyYoArrayAction({
            arrayKey: null,
            objKeyName: "visibleIndex",
            objKey: element.days.length - 1,
            indexInArray: null,
            elementIndex: elementIndex,
            fieldArray: "employees",
        }));
        dispatch(
            inputValidationInArray({
                fieldElement: "jobDateValid",
                bool: false,
                indexArray: elementIndex,
                arrayField: "employees",
            }));

        // setDatesArray(prev => {
        //     const temp = [...prev];
        //     temp.push(date);
        //     return temp;
        // });
    }, []);


    const removeItemHandler = useCallback((index) => {
        console.log("WWOORRKK");
        dispatch(removeObjfromArrayAction({
            arrayKeyRemove: "days",
            indexInArrayRemove: index,
            elementIndexRemove: elementIndex,
            fieldArrayRemove: "employees",
        }));
    }, []);
    // function removeItemHandler(index) {
    // event.preventDefault();
    // console.log("WWOORRKK", index);
    // console.log(elementIndex);
    // dispatch(removeObjfromArrayAction({
    //     arrayKeyRemove: "days",
    //     indexInArrayRemove: index,
    //     elementIndexRemove: elementIndex && elementIndex,
    //     fieldArrayRemove: "employees",
    // }));
    // }

    // const item = document
    // useEffect(() => {
    //
    //     setVisible(prev => {
    //         console.log(prev);
    //         return prev;
    //
    //     });
    //     // setVisible((prev) => prev);
    // }, [currentRequest]);


    return (
        <>
            <div className="date-selector-list">
                {
                    element.days.map((el, index) => {
                        return (


                            <button
                                className={`date-selector-list__wrapper ${currentRequest &&
                                currentRequest["employees"] &&
                                currentRequest["employees"][elementIndex] &&
                                currentRequest["employees"][elementIndex].visibleIndex === index && "focus"}   ${isValidKey && isValidKey && isValidKey?.days[index]?.fieldValid ? "errorBorder" : ""}  `}
                                key={uuid()}
                                tabIndex={0}
                                onClick={(event) => {
                                    dispatch(addObjKeyYoArrayAction({
                                        arrayKey: null,
                                        objKeyName: "visibleIndex",
                                        objKey: index,
                                        indexInArray: null,
                                        elementIndex: elementIndex,
                                        fieldArray: "employees",
                                    }));

                                    if (currentRequest &&
                                        currentRequest["employees"] &&
                                        currentRequest["employees"][elementIndex] &&
                                        currentRequest["employees"][elementIndex].visibleIndex === index) {
                                        event.target.classList.add("focus");

                                    } else {
                                        event.target.classList.remove("date-selector-list__wrapper--focus");

                                    }
                                }}
                                onBlur={(event) => {
                                    // setVisible(false);

                                    event.target.classList.remove("date-selector-list__wrapper--focus");
                                }}
                            >
                                {
                                    el.jobDate ?
                                        <>
                                            <ItemBox value={moment(el.jobDate).format("DD.MM.YYYY")} />

                                            <RoundedRemoveItemButton
                                                // handler={}
                                                index={index}
                                                handler={removeItemHandler}
                                            />


                                        </> :
                                        null

                                }


                            </button>


                        );
                    })
                }
                <div className={`date-selector-list__add-date-box ${isValidKey.jobDateValid ? "errorBorder" : ""}`}>
                    <div>Добавить дату привлечения</div>
                    <div style={{ zIndex: "51" }}>
                        <ReactDatePicker
                            popperClassName="some-custom-class"
                            popperPlacement="bottom-start"
                            minDate={new Date(moment().add(1, "days"))}
                            excludeDates={element.days && element.days.map((el, index) => new Date(el.jobDate))}
                            locale={ru}
                            onChange={addItemHandler}
                            customInput={<AddBox />}
                            dateFormat={"dd.MM.yyyy"}
                        />
                    </div>
                </div>
            </div>
            {
                currentRequest &&
                currentRequest["employees"] &&
                currentRequest["employees"][elementIndex] &&
                currentRequest["employees"][elementIndex].days.map((el, index) => {

                    return (
                        <>
                            {
                                // (visible || visible === 0) &&
                                // visible === index ?
                                currentRequest &&
                                currentRequest["employees"] &&
                                currentRequest["employees"][elementIndex] &&
                                currentRequest["employees"][elementIndex].visibleIndex === index ?

                                    (
                                        <DayItem isValidKey={isValidKey} index={index} setVisible={setVisible}
                                            elementIndex={elementIndex}
                                            parentStyle={parentStyle} />) : null
                            }
                        </>
                    );
                })
            }

        </>

    );
};

export default React.memo(DateSelectorList);
