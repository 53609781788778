import openEye from "../../../../assets/icons/authIcons/ic_eyes_open.svg";
import closedEye from "../../../../assets/icons/authIcons/eye.svg";
import { isTerminal } from "../../../../constants";

import React, { useState } from "react";

isTerminal ? import("./BasicInputTerminal.scss") : import("./BasicInput.scss");

const BasicInput = (
    {
        passedState,
        correctPassword,
        label,
        stateType,
        validData,
        stateHandler,
        setCorrectPassword,
        eye,
        type,
        underline,
        placeholder,
    },
) => {
    const [eyeState, setEyeState] = useState();

    function toggleEye(e) {
        setEyeState(!eyeState);
    }

    console.log(stateHandler, passedState);

    return (
        <div className={
            `basic-input__container 
        ${
        correctPassword ? "basic-input__container__buffer" : ""
        }`
        }>
            {label ?
                <label
                    className="basic-input__label"
                    htmlFor="basic-input">{label}</label> : null
            }
            <input
                autoComplete="new-password"
                maxLength={255}
                name="basic-input"
                placeholder={placeholder}
                className={`
                ${
        stateType === "employeeComment"
            ? "basic-input bi-comment"
            : "basic-input"
        }
                ${
        validData === "invalid"
            ? "bi-danger"
            : null
        }
                ${
        correctPassword
            ? null
            : "incorrect-password"
        }
                `}
                // do not forget to pass state type and state handler
                onChange={(event) => stateHandler(prev => {
                    setCorrectPassword && setCorrectPassword(true);
                    if (stateType) {
                        return { ...prev, [`${stateType}`]: event.target.value };
                    } else {
                        return event.target.value;
                    }

                })}
                type={eye ? eyeState ? "text" : "password" : type} />
            {
                validData === "invalid"
                    ? <div className="bi-danger__underline">
                        обязательное поле
                    </div>
                    : null
            }
            {
                correctPassword === false
                    ? <div className="incorrect-password__underline">
                        Пароль введен неверно
                    </div>
                    : null
            }
            {
                underline
                && <div className="basic-input__underline">
                    {underline}
                </div>
                // : null
            }
            {
                eye
                    ? eyeState
                        ? <img
                            className="basic-input__eye__open"
                            onClick={toggleEye}
                            src={openEye}
                            alt="openEye" />
                        : <img
                            className="basic-input__eye__close"
                            onClick={toggleEye}
                            src={closedEye}
                            alt="closedEye" />
                    : null
            }
        </div>
    );
};

export default BasicInput;
