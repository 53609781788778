import { EmployeesListCheckbox } from "../EmployeesListCheckbox/EmployeesListCheckbox";

import EmployeesListContext from "../EmployeesListContext/EmployeesListContext";

import WOHHelper from "../WOHHelper.service";

import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

export const EmployeesListItem = ({ employer, employees, selectedE, setSelectedE }) => {
    const [selected, setSelected] = useState(false);

    const { employeesSelected, employeesSelectedToggle } = useContext(EmployeesListContext);

    function onChangeHandler() {
        setSelected((prev) => !prev);
        employeesSelectedToggle(employer.pid);
        if (!selectedE.length || !selectedE.includes(employer.pid)) {
            setSelected(true);
            setSelectedE((prev) => [...prev, employer.pid]);
        } else {
            setSelectedE(selectedE.filter((item) => item !== employer.pid));
            setSelected(false);

        }
    }

    const card = {
        jobDate: WOHHelper.getJobDates(employer.days).join(", "),
        jobHours: WOHHelper.getJobHours(employer.days).join(", "),
        compensationType: WOHHelper.getCompensationTypes(employer.days).join(", "),
        restDate: WOHHelper.getRestDates(employer.days).join(", "),
        stateName: employer.agreement.stateName,
        state: employer.agreement.state,
    };

    useEffect(() => {
        if (WOHHelper.statusFiltered(employer.status)) {
            if (!employeesSelected.has(employer.pid)) {
                setSelected(true);
                employeesSelectedToggle(employer.pid);
            } else {
                setSelected(employeesSelected.get(employer.pid));
            }
        }
    }, [employeesSelected]);

    return (
        <div className={[
            "request-modal__table__line",
            "employee-request_status__",

        ].join(" ")}>
            {/*+ WOHHelper.getStatusType(employer.status),*/}
            <div className={`left-column 
             ${card?.stateName && card?.stateName === "Отклонено" ? "employee-request_status__not-confirmed" : null} `}>
                <div className="employer-name">
                    <p>
                        {card?.stateName && card?.stateName !== "Отклонено" ?
                            <EmployeesListCheckbox
                                checked={selected ||
                                    employeesSelected.has(employer.pid) && employeesSelected.get(employer.pid)}
                                onChange={onChangeHandler}
                                disabled={WOHHelper.statusFiltered(card.stateName)}
                            /> : null}
                        {employer.fullName}</p>

                </div>
                <div className="employer-tabel">{employer.tabNumber}</div>
                <div className="employer-grade">{employer.jobTitle}</div>
            </div>
            <div className="right-column">

                <div className="employer-info">
                    <span>Дата привлечения - {card.jobDate} </span>
                </div>
                <div className="employer-info">
                    <span>Количество часов - {card.jobHours}</span>
                </div>
                <div className="employer-info">
                    <span>Порядок компенсации - {card.compensationType}</span>
                </div>

                {
                    card.restDate &&
                    <div className="employer-info">
                        <span>Дата отдыха - {card.restDate}</span>
                    </div>
                }

                <div className="employer-info">
                    <span>Статус -{" "}
                        <span
                            className={`employee-request-status
                         ${card?.stateName && card?.stateName === "Согласовано" ? "green" : null}
                          ${card?.stateName && card?.stateName === "На согласовании" ? "black" : null}
                          ${card?.stateName && card?.stateName === "Отклонено" ? "red" : null}`}>
                            {card.stateName}
                        </span>
                    </span>
                </div>

            </div>
        </div>
    );
};
