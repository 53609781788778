import ModalBanOverlay from "../../../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";

import CustomButton from "../../../../../../common/Button/CustomButton/CustomButton";

import OkButton from "../../../../../../common/Button/OKButton/OKButton";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React from "react";


const ModalSuccess = ({
    props: {
        reqNum,
        responseData,
        currentRequest,
        description,
    },
    back, next, newTuple,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const currentRequest = useSelector(state => state.requests.currentRequest);
    // console.log("RESPONSE DATA: ", responseData);
    return (
        <ModalBanOverlay shown={true}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                background: "#FFFFFF",
                minHeight: "40vh",
                padding: "20px",
                gap: "30px",
            }}>
                {
                    description ?
                        <>
                            <div className="sub-header">
                                {description && description?.head}
                            </div>
                            <div>
                                {description && description.subHead}
                                <br />
                                Уточнить ее статус вы можете в разделе <span onClick={() => navigate("/documents")}
                                    className={"colored-text"}>«Мои заявки»</span>
                            </div>
                        </>
                        :
                        <>
                            <div className="sub-header">
                                заявка отправлена
                            </div>
                            <div>
                                Ваша заявка
                                № {responseData && responseData?.number ? responseData?.number : "001"} «Привлечение к
                                работе в выходные
                                и
                                праздничные дни»
                                отправлена на согласование.<br />
                                Уточнить ее статус вы можете в разделе <span onClick={() => navigate("/documents")}
                                    className={"colored-text"}>«Мои заявки»</span>
                            </div>
                        </>

                }

                <div style={{
                    display: "flex",
                    width: "800px",
                    justifyContent: "space-around",
                    alignItems: "center",

                }}>
                    {
                        description ?
                            <OkButton
                                handler={() => back()}
                                title={"ок"} />
                            : <CustomButton
                                // onValidation={onValidation}
                                regular={true}
                                title={"ок"}
                                // modal={setModalSentStatus}
                                route={"/documents"} />
                    }


                    {/*<OkButton*/}
                    {/*    // onValidation={onValidation}*/}
                    {/*    handler={next}*/}
                    {/*    title={"ок"} />*/}
                </div>
            </div>

        </ModalBanOverlay>

    );
};

export default ModalSuccess;
