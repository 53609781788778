import DropdownMenuTest from "../../../../../../common/Menu/DropdownMenuTest/DropdownMenuTest";
import { jobHours } from "../Employe/jobHours.data";
import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import DatePopupSelector from "../../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";

import {
    addObjKeyYoArrayAction,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addObjKeyYoArray.action";

import DropdownMenuTestForArray from "../../../../../../common/Menu/DropdownMenuTestForArray/DropdownMenuTest";

import {
    attachmentsValidation,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/attachmentsValidation";

import {
    validationControlCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";


import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const DayItem = ({ parentStyle, elementIndex, index, setVisible, field }) => {
    const dispatch = useDispatch();
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const subordinates = useSelector(state => state.requests.subordinates);
    const compensationTypes = useSelector(state => state.requests.compensationTypes);

    // const addItemHandler = useCallback((element) => {
    //
    //     dispatch(addObjKeyYoArrayAction({
    //         arrayKey: "days",
    //         objKeyName: "jobDate",
    //         objKey: moment(date).format("YYYY-MM-DD"),
    //         indexInArray: element.days.length,
    //         elementIndex: elementIndex,
    //         fieldArray: "employees",
    //     }));
    //     // setDatesArray(prev => {
    //     //     const temp = [...prev];
    //     //     temp.push(date);
    //     //     return temp;
    //     // });
    // }, []);
    function selectDropdown(event, element, stateProperty) {

        dispatch(addObjKeyYoArrayAction({
            arrayKey: "days",
            objKeyName: stateProperty,
            objKey: element.id,
            indexInArray: index,
            elementIndex: elementIndex,
            fieldArray: "employees",
        }));
        switch (stateProperty) {
        case "jobHours" :
            dispatch(attachmentsValidation({
                arrIndex: elementIndex,
                attachIndex: index,
                falsyAttach: true,
                arrayName: "employees",
                calledArray: "days",
                fieldValidation: "jobHoursValid",
            }));
            break;
        case "compensationType" :
            dispatch(attachmentsValidation({
                arrIndex: elementIndex,
                attachIndex: index,
                falsyAttach: true,
                arrayName: "employees",
                calledArray: "days",
                fieldValidation: "compensationTypeValid",
            }));
            break;
        default:
            return null;
        }
        dispatch(validationControlCurrentRequest("validRequest", true));
        dispatch(attachmentsValidation({
            arrIndex: elementIndex,
            attachIndex: index,
            falsyAttach: false,
            arrayName: "employees",
            calledArray: "days",
            fieldValidation: "fieldValid",
        }));
        setVisible(index);
    }

    function changeDateHandler(field, date) {
        const value = moment(date).format("YYYY-MM-DD");
        dispatch(addObjKeyYoArrayAction({
            arrayKey: "days",
            objKeyName: field,
            objKey: value,
            indexInArray: index,
            elementIndex: elementIndex,
            fieldArray: "employees",
        }));
        dispatch(attachmentsValidation({
            arrIndex: elementIndex,
            attachIndex: index,
            falsyAttach: true,
            arrayName: "employees",
            calledArray: "days",
            fieldValidation: "restDateValid",
        }));
        dispatch(attachmentsValidation({
            arrIndex: elementIndex,
            attachIndex: index,
            falsyAttach: false,
            arrayName: "employees",
            calledArray: "days",
            fieldValidation: "fieldValid",
        }));
        dispatch(validationControlCurrentRequest("validRequest", true));
        // setVisible(index);

    }

    // console.log("Child RENDER", currentRequest &&
    //     currentRequest["employees"] &&
    //     currentRequest["employees"][elementIndex] &&
    //     currentRequest["employees"][elementIndex] &&
    //     currentRequest["employees"][elementIndex]["days"][index]);

    return (
        <div className={`${parentStyle}__employe__details`}>
            <div>
                <DropdownMenuTestForArray
                    helper={
                        "Рекомендуем привлечение работника(-ов) в выходные, праздничные дни не менее, чем на 4 часа"
                    }
                    currentRequest={
                        currentRequest &&
                        currentRequest["employees"] &&
                        currentRequest["employees"][elementIndex] &&
                        currentRequest["employees"][elementIndex] &&
                        currentRequest["employees"][elementIndex]["days"][index] ?
                            currentRequest["employees"][elementIndex]["days"][index] : false
                    }
                    styleName={"reason"}
                    label={"Количество часов привлечения *"}
                    listOfOptions={jobHours}
                    stateProperty={"jobHours"}
                    isValidKey={
                        currentRequest &&
                        currentRequest["employees"] &&
                        currentRequest["employees"][elementIndex] &&
                        currentRequest["employees"][elementIndex]["days"] &&
                        currentRequest["employees"][elementIndex]["days"][index] &&
                        currentRequest["employees"][elementIndex]["days"][index]["jobHoursValid"] === false ?
                            currentRequest["employees"][elementIndex]["days"][index]["jobHoursValid"] : true
                    }
                    selectOptionClick={selectDropdown}
                />
            </div>
            <div>
                <DropdownMenuTestForArray

                    currentRequest={currentRequest &&
                    currentRequest["employees"] &&
                    currentRequest["employees"][elementIndex] &&
                    currentRequest["employees"][elementIndex] &&
                    currentRequest["employees"][elementIndex]["days"] &&
                    currentRequest["employees"][elementIndex]["days"][index] ?
                        currentRequest["employees"][elementIndex]["days"][index] : false}
                    styleName={"reason"}
                    label={"Порядок компенсации *"}
                    listOfOptions={compensationTypes}
                    stateProperty={"compensationType"}
                    isValidKey={
                        currentRequest &&
                        currentRequest["employees"] &&
                        currentRequest["employees"][elementIndex] &&
                        currentRequest["employees"][elementIndex]["days"] &&
                        currentRequest["employees"][elementIndex]["days"][index] &&
                        currentRequest["employees"][elementIndex]["days"][index]["compensationTypeValid"] === false ?
                            currentRequest["employees"][elementIndex]["days"][index]["compensationTypeValid"] : true
                    }
                    selectOptionClick={selectDropdown}
                />
            </div>

            {
                currentRequest && currentRequest["employees"] &&
                currentRequest["employees"][elementIndex] &&
                currentRequest["employees"][elementIndex] &&
                currentRequest["employees"][elementIndex]["days"][index] &&
                currentRequest["employees"][elementIndex]["days"][index]["compensationType"] &&
                currentRequest["employees"][elementIndex]["days"][index]["compensationType"] == 2 &&
                <div>
                    <DatePopupSelector
                        field={"restDate"}
                        excludeDates={currentRequest &&
                            currentRequest["employees"] &&
                            currentRequest["employees"][elementIndex] &&
                            currentRequest["employees"][elementIndex] &&
                            currentRequest["employees"][elementIndex]["days"] &&
                            currentRequest["employees"][elementIndex]["days"].map((el, index) => new Date(el.jobDate))}

                        label={<>Выберите дату отдыха<span className="required_text"> *</span></>}
                        errorMessage={
                            currentRequest &&
                            currentRequest["employees"] &&
                            currentRequest["employees"][elementIndex] &&
                            currentRequest["employees"][elementIndex]["days"] &&
                            currentRequest["employees"][elementIndex]["days"][index] &&
                            currentRequest["employees"][elementIndex]["days"][index]["restDateValid"] === false
                                ? "Обязательное поле" : ""
                        }
                        changeHandler={changeDateHandler}

                        propsDate={
                            currentRequest &&
                            currentRequest["employees"] &&
                            currentRequest["employees"][elementIndex] &&
                            currentRequest["employees"][elementIndex] &&
                            currentRequest["employees"][elementIndex]["days"] &&
                            currentRequest["employees"][elementIndex]["days"][index] &&
                            currentRequest["employees"][elementIndex]["days"][index].restDate
                        }
                        minDate={new Date(moment(currentRequest["employees"][elementIndex]["days"][index].jobDate).add(1, "days"))}

                    />
                </div>

            }

        </div>
    );
};

export default React.memo(DayItem);
