import { COMPENSATION_TYPE } from "./compensation.type";
import { WOH_STATUS_TYPE, WOH_STATUS_TYPE_CONFIRMED, WOH_STATUS_TYPE_NOT_CONFIRMED } from "./woh_status.type";

import { jobHours } from "../RecruitmentToWorkOnHolidays/Employe/jobHours.data";

import moment from "moment";

class WOHHelper {

    /**
     * Convert employee object to jobDate array
     * @param {Object} employee - employees data object
     * @returns {*[]} array of jobDate for current employee
     */
    static getJobDates(employee) {
        const days = [];
        employee && employee.length && employee.map((i) => i.jobDate && days.push(moment(i.jobDate).format("DD.MM.YYYY")));
        return days;
    }

    /**
     * Convert employee object to restDate array
     * @param {Object} employee - employees data object
     * @returns {*[]} array of restDate for current employee
     */
    static getRestDates(employee) {
        const out = [];
        // employee && employee.length && employee.map((i) => i.jobDate && days.push(moment(i.jobDate).format("DD.MM.YYYY")));

        employee && employee.length && employee.map((i) => i.restDate && out.push(moment(i.restDate).format("DD.MM.YYYY")));
        return out;
    }

    /**
     * Convert employee object to compensation name array
     * @param {Object} employee - employees data object
     * @returns {*[]} array of compensation name for current employee
     */
    static getCompensationTypes(employee) {

        // employee.map((i) => out.push(COMPENSATION_TYPE[i.compensationTypeName]));
        return employee.map((el) => el.compensationTypeName);
    }

    /**
     * Calculate total jobHours for current employee
     * @param {Object} employee - employees data object
     * @returns {*[]} total jobHours for current employee
     */
    static getJobHours(employee) {
        const workHours = [];
        employee.map((i) => i.jobHours && workHours.push(i.jobHours));
        return workHours;
    }

    /**
     * Get status name by status_id
     * @param {number} status_id - id for status
     * @returns {string} name for current status
     */


    /**
     * Get status type by status_id
     * @param {number} status_id - id for status
     * @returns {string} type for current status
     */
    // static getStatusType(status_id) {
    //     return WOH_STATUS_TYPE[status_id].type;
    // }

    /**
     * Checks whether the current status_id can be included in the final request
     * @param {number} status_id - id for status
     * @returns {boolean} type for current status
     */
    static statusFiltered(stateName) {
        console.log("stateName", stateName);
        if (stateName === "На согласовании") {
            return true;
        }
        if (stateName === "Не согласовано") {
            return true;
        }

        return false;
    }
}

export default WOHHelper;
