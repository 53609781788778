import triangle from "./Triangle.svg";

import { isTerminal } from "../../../../../constants";

import { DatepickerCustomField } from "../../../DatepickerCustomField/DatepickerCustomField";

import React, { useState } from "react";
import DatePicker from "react-datepicker";

isTerminal ? import("./DatePopupSelectorTerminal.scss") : import("./DatePopupSelector.scss");


const DatePopupSelector = (
    {
        propsDate,
        minDate,
        maxDate,
        field,
        changeHandler,
        label,
        errorMessage,
        excludeDates,
    },
) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [arrow, setArrow] = useState(false);

    function handleCalendarOpen() {
        setArrow(true);
    }

    function handleCalendarClose() {
        setArrow(false);
    }

    return (
        <div className="date-popup-selector">
            <div className="date-popup-selector__label">
                {
                    label
                        ? label
                        : "Выберите дату *"
                }
            </div>
            <div className="date-popup-selector__datepicker-container">
                <DatePicker
                    popperClassName="some-custom-class"
                    popperPlacement="bottom-start"
                    // wrapperClassName="date-popup-selector__datepicker"
                    customInput={<DatepickerCustomField />}
                    className={
                        errorMessage
                            ? "date-popup-selector__datepicker-error"
                            : "date-popup-selector__datepicker"
                    }
                    minDate={
                        minDate
                            ? minDate
                            : new Date()
                    }
                    maxDate={
                        maxDate
                            ? maxDate
                            : null

                    }
                    excludeDates={excludeDates}
                    dateFormat="dd.MM.yyyy"
                    locale={"ru"}
                    selected={
                        propsDate
                            ? new Date(propsDate)
                            : selectedDate
                    }
                    onChange={
                        changeHandler
                            ? (date) => changeHandler(field, date)
                            : setSelectedDate
                    }
                    onCalendarOpen={handleCalendarOpen}
                    onCalendarClose={handleCalendarClose}
                />
                <img
                    className={
                        `date-popup-selector__datepicker-arrow-${arrow ? "up" : "down"}`
                    }
                    src={triangle}
                    alt="arrow" />
            </div>
            {
                errorMessage
                    ? (<div className="date-popup-selector__error-message">
                        {errorMessage}
                    </div>)
                    : (<div className="date-popup-selector__buffer">

                    </div>)
            }
        </div>
    );
};

export default DatePopupSelector;
