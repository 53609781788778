import { $api } from "../../../services/request.config";
import { setCurrentDocumentAction } from "../../Requests/CurrentRequest/setCurrentDocument.action";

export const getGroupCurrentRequestThunkAction = (uid, props) => async (dispatch) => {
    try {
        const response = await $api(`/v1/managers/requests/rvd/applications/${uid}`);
        dispatch(setCurrentDocumentAction({ currentRequestCard: {}, currentRequest: response.data }));

        console.log("RESPONSE DETAILS", response.data);
    } catch (e) {
        throw  new Error(e?.response);
    }
};
