import RecruitmentToWorkOnHolidaysContent from "./RecruitmentToWorkOnHolidaysContent";
import EmployeesListProvider from "./EmployeesListContext/EmployeesListProvider";

import ModalBanOverlay from "../../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";


const RecruitmentToWorkOnHolidaysModal = ({
    setModalStatus, setStageModalWindow, testHOC, setSelectedE,
    selectedE,
}) => {

    return (


        <EmployeesListProvider>
            <RecruitmentToWorkOnHolidaysContent
                selectedE={selectedE}
                setSelectedE={setSelectedE}
                setStageModalWindow={setStageModalWindow}
                testHOC={testHOC}
                setModalStatus={setModalStatus} />
        </EmployeesListProvider>

    );
};

export default RecruitmentToWorkOnHolidaysModal;
