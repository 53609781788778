import EmployeesListContext from "./EmployeesListContext";

import { useState } from "react";

const EmployeesListProvider = ({ children }) => {
    const [employeesSelected, setEmployeesSelected] = useState(new Map());

    const employeesSelectedToggle = (pid) => {
        setEmployeesSelected((prev) => {
            prev.has(pid) ? prev.set(pid, !prev.get(pid)) : prev.set(pid, true);
            return prev;
        });
    };

    return (
        <EmployeesListContext.Provider value={{ employeesSelected, employeesSelectedToggle }}>
            {children}
        </EmployeesListContext.Provider>
    );
};

export default EmployeesListProvider;