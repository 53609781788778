import { EmployeesListItem } from "../EmployeesListItem/EmployeesListItem";
import {
    getMealCardBalanceThunkAction,
} from "../../../../../../../redux/actions/User/profile/Widgets/Thunk/getFoodCardBalance.thunk.action";

export const EmployeesList = ({ employees, selectedE, setSelectedE }) => {
    return (
        !employees.length || !employees ? <div className="employees-list-empty">Нет сотрудников в заявке</div>
            : employees.map((employer) =>
                <EmployeesListItem
                    selectedE={selectedE}
                    setSelectedE={setSelectedE}
                    employees={employees} employer={employer} key={employer.pid} />,
            )
    );
};
