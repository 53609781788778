import { authUserReducer } from "./Auth/authUserReducer";
import { authNumberReducer } from "./Auth/authNumberReducer";
import { startReducer } from "./Auth/startReducer";
import { resetStatePasswordReducer } from "./Auth/resetStatePasswordReducer";
import { requestReducer } from "./Requests/request.reducer";
import { workTimeReducer } from "./WorkTime/workTime.reducer";
import { personalDataReducer } from "./PersonalData/personalData.reducer";
import { employeesReducer } from "./Employees/employees.reducer";
import { documentsReducer } from "./Documents/Documents.reducer";
import { myDivisionReducer } from "./MyDivision/MyDivision.reducer";
import { payrollReducer } from "./Payroll/payrollReducer";

import { combineReducers } from "redux";

export const rootReducer = combineReducers({
    user: authUserReducer,
    authNumber: authNumberReducer,
    isAuth: startReducer,
    resetPassword: resetStatePasswordReducer,
    requests: requestReducer,
    workTime: workTimeReducer,
    personalData: personalDataReducer,
    employees: employeesReducer,
    documents: documentsReducer,
    myDivision: myDivisionReducer,
    payroll: payrollReducer,
});
