import WOHHelper from "../WOHHelper.service";

import React from "react";

export const EmployeesListItemFiltered = ({ employer }) => {


    const card = {
        jobDate: WOHHelper.getJobDates(employer.days).join(", "),
        jobHours: WOHHelper.getJobHours(employer.days).join(", "),
        compensationType: WOHHelper.getCompensationTypes(employer.days).join(", "),
        restDate: WOHHelper.getRestDates(employer.days).join(", "),
        stateName: employer.agreement.stateName,
        state: employer.agreement.state,
    };


    return (
        <div className={[
            "request-modal__table__line",
            // "employee-request_status__" + WOHHelper.getStatusType(employer.status),
        ].join(" ")}>
            <div className="left-column">
                <div className="employer-name">
                    <span>{employer.fullName}</span>
                </div>
                <div className="employer-tabel">{employer.tabNumber}</div>
                <div className="employer-grade">{employer.jobTitle}</div>
            </div>
            <div className="right-column">
                <div className="employer-info">
                    <span>Дата привлечения - {card.jobDate}</span>
                </div>
                <div className="employer-info">
                    <span>Количество часов - {card.jobHours}</span>
                </div>
                <div className="employer-info">
                    <span>Порядок компенсации - {card.compensationType}</span>
                </div>

                {
                    card.restDate &&
                    <div className="employer-info">
                        <span>Дата отдыха - {card.restDate}</span>
                    </div>
                }

                {/*<div className="employer-info">*/}
                {/*    <span>Статус - {" "}*/}
                {/*        <span className="employee-request-status green">*/}
                {/*            {card.stateName}*/}
                {/*        </span>*/}
                {/*    </span>*/}
                {/*</div>*/}


            </div>
        </div>
    );
};
