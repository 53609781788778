import { EmployeesList } from "./EmployeesList/EmployeesList";

import EmployeesListContext from "./EmployeesListContext/EmployeesListContext";

import { EmployeesListFiltered } from "./EmployeesListFiltered/EmployeesListFiltered";

import CustomLoader from "../../../../../common/CustomLoader/CustomLoader";

import { Modal } from "../../../../../common/Modal/Modal";

import {
    unsetCurrentDocumentAction,
} from "../../../../../../redux/actions/Requests/CurrentRequest/setCurrentDocument.action";

import React, { useContext, useEffect, useState } from "react";
import "./RecruitmentToWorkOnHolidaysModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";


const RecruitmentToWorkOnHolidaysContent = ({
    setModalStatus,
    setStageModalWindow,
    testHOC,
    setSelectedE,
    selectedE,
}) => {

    const dispatch = useDispatch();
    const requestCard = useSelector((state) => state.requests?.singleDocument?.currentRequest);


    const [step, setStep] = useState(0);
    // const [selectedE, setSelectedE] = useState([]);
    const { employeesSelected } = useContext(EmployeesListContext);

    useEffect(() => {

        console.log("requestCard", employeesSelected.size);
    }, [employeesSelected]);

    console.log("requestCard", selectedE);


    // useEffect(() => {
    //     return () => {
    //         dispatch(unsetCurrentDocumentAction());
    //     };
    // }, []);
    return (
        <div className="request-modal request-modal-woh">
            {
                !requestCard ? <CustomLoader /> :
                    <>

                        <div className="request-modal__header">
                            <span> {requestCard.memo ? "Служебная записка" : "Заявка"} {requestCard && requestCard.number && requestCard.number} Работа в выходной, праздничный день</span>
                        </div>
                        <div className="request-modal__table">
                            <div className="request-modal__table__line">
                                <div className="left-column">дата подачи</div>
                                <div
                                    className="right-column">{moment(requestCard.createdAtUtc).format("DD.MM.YYYY")}</div>
                            </div>
                            <div className="request-modal__table__line">
                                <div className="left-column">Статус заявки</div>
                                <div className="right-column">{requestCard.stateName}</div>
                            </div>
                            <div className="request-modal__table__line">
                                <div className="left-column">Расшифровка причины привлечения к работе в выходные,
                                    праздничные
                                    дни
                                </div>
                                <div className="right-column">{requestCard.reason}</div>
                            </div>
                            <div className="employees-list-items">
                                {step === 1 || requestCard.memo
                                    ? <EmployeesListFiltered

                                        employees={requestCard && (requestCard.memo ? requestCard?.memo?.employees && requestCard.memo?.employees : requestCard?.employees?.filter((i) =>
                                            employeesSelected.has(i.pid) && employeesSelected.get(i.pid)))}
                                    />
                                    : <EmployeesList
                                        selectedE={selectedE}
                                        setSelectedE={setSelectedE}
                                        employees={requestCard && requestCard.employees && requestCard.employees} />
                                }
                            </div>

                            {requestCard.memo && (
                                <div className="request-modal__table__line">
                                    <div className="left-column">Служебная записка</div>
                                    <div className="right-column">

                                        <p>
                                            <a href="/" className="request-colored-link">Служебная записка по
                                                подтвержденным
                                                работникам</a>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>

                        {step === 1 ?
                            (
                                <div className="buttons-wrapper">
                                    <button className="ui-u-button request-modal__button" disabled=""
                                        onClick={() => {
                                            setStep(0);

                                        }
                                        }>
                                        Назад
                                    </button>
                                    <button className="ui-u-button request-modal__button" disabled=""
                                        onClick={() => {
                                            setModalStatus(false);

                                            setStageModalWindow(testHOC);
                                        }}>
                                        направить
                                    </button>
                                </div>
                            ) : (
                                <div className="buttons-wrapper">
                                    <button
                                        onClick={() => {
                                            setModalStatus(false);
                                            setStageModalWindow(null);

                                        }}
                                        className="ui-u-button request-modal__button">
                                        закрыть
                                    </button>
                                    {requestCard.memo ? null :
                                        <button className="ui-u-button request-modal__button"
                                            disabled={!selectedE.length}
                                            onClick={() => setStep(1)}>
                                            Далее
                                        </button>
                                    }

                                </div>
                            )
                        }
                    </>
            }

        </div>
    );
};

export default RecruitmentToWorkOnHolidaysContent;
