import OkButton from "../../../../Button/OKButton/OKButton";
import CustomButton from "../../../../Button/CustomButton/CustomButton";

import { isTerminal } from "../../../../../../constants";

import {
    reqruitmentValidationThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/RecruitmentToWork/thunk/reqruitmentValidation.thunk.action";

import ModalBanOverlay from "../../../ModalBanOverlay/ModalBanOverlay";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import React from "react";
import { element } from "prop-types";

isTerminal ? import("./RequestVerificationTerminal.scss") : import("./RequestConfirmation.scss");

const RequestConfirmation = ({ props, back, next, newTuple }) => {

    const currentRequest = useSelector(state => state.requests.currentRequest);

    const [validationState, setValidationState] = useState(false);
    console.log("newTuple: ", newTuple);
    console.log("onValidation: ", props);
    console.log(currentRequest && currentRequest.validatedData);


    return (
        <ModalBanOverlay shown={true}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                background: "#FFFFFF",
                minHeight: "40vh",
                width: "70vw",
                textAlign: "center",
                padding: "20px",
                gap: "30px",
            }}>
                <div className="request-verification__head">
                    Вы уверены, что хотите направить заявку?
                </div>
                <div className="request-verification__input">
                    {
                        currentRequest &&
                        currentRequest.validatedData &&
                        currentRequest.validatedData.validControl &&
                        currentRequest.validatedData.validControl.map((element, index) => {
                            return (
                                <div>
                                    <span>{index + 1}.{" "} </span>
                                    {element.details.length && element.details.map((day) => {
                                        return (
                                            <span>{day.reason}</span>
                                        );
                                    })}
                                </div>
                            );
                        })
                    }
                </div>


                <div style={{
                    display: "flex",
                    width: "800px",
                    justifyContent: "space-around",
                    alignItems: "center",

                }}>
                    {/*<CustomButton*/}
                    {/*    onValidation={onValidation}*/}
                    {/*    regular={true}*/}
                    {/*    title={"отмена"}*/}
                    {/*    modal={back}*/}
                    {/*    route={"/main/hr-services"}*/}
                    {/*/>*/}
                    <OkButton
                        onValidation={props.onValidation}
                        handler={() => back()}
                        title={"отмена"} />
                    <OkButton
                        onValidation={validationState}
                        handler={() => {
                            next(newTuple, props);
                            setValidationState(true);
                        }}
                        title={"направить"} />
                </div>
            </div>

        </ModalBanOverlay>

    );
};

export default RequestConfirmation;
