import { initialState } from "../../init/initialState";

import {
    CLEAR_EMPLOYEE_BY_STRUCT_ID,
    CLEAR_FUNCTION_MARKERS, CLEAR_SELECTED_STRUCT_ITEM,
    CLEAR_STRUCT_TOP,
    CLEAR_STRUCT_TREE, EDIT_STRUCTURE_ITEM, GET_EMPLOYEE_BY_STRUCT_ID,
    GET_FUNCTION_MARKERS,
    GET_STRUCT_TOP,
    GET_STRUCT_TREE, GET_SUBORDINATES, SELECT_DROPDOWN_MENU_ITEM, SET_SELECTED_STRUCT_ITEM,
} from "../../enums/Request/leader.requests.types";

import {
    CLEAR_ATTACHMENTS,
    DELETE_APPLICATION_FILE,
    DELETE_ATTACHMENT,
    DELETE_ONE_REQUEST,
    GET_ALL_HR_DOCS,
    GET_ALL_REQUESTS,
    GET_APPLICATION_FILE,
    GET_ATTACHMENTS,
    GET_CURRENT_HR_DOCS,
    GET_LEAVE_TYPES,
    GET_REASONS_TYPES,
    SET_CURRENT_REQUEST,
    UNSET_CURRENT_HR_DOCS,
    UNSET_CURRENT_REQUEST,
    GET_LEADER_REQUESTS,
    GET_ALL_REFERENCES,
    GET_CURRENT_REQUEST,
    CLEAR_CURRENT_REQUEST,
    GET_REQUESTS_RESCHEDULE,
    GET_DOCUMENT_OBTAINING,
    GET_DOCUMENT_REASONS,
    GET_LAYOFF_ADRESS,
    CREATE_SIGN_REQUEST,
    GET_RESCHEDULE_SHIFT_DAYS,
    CLEAR_SHIFT_DAYS,
    GET_MATERINITY_DIS_DOCS,
    SET_VALIDATED_REQUEST,
    GET_EDUCATIONAL_PROGRAMS,
    GET_COURSE_NUMBERS,
    GET_PLANNED_REVOCATION,
    DROPDOWN_SELECT_STUDY_LEAVE_REQUEST,
    ADD_BLOOD_DONATION_INFORMATION,
    ADD_EMPTY_STRING,
    GET_INTERNSHIPS_TYPES,
    GET_INTERNSHIPS_STRUCTS,
    DROPDOWN_SELECT_UNPAID_PRACTICE,
    GET_INTERNSHIPS_STRUCTS_EMPLOYEES,
    DELETE_STUDENT_HANDLER_CURRENT_REQUEST,
    ADD_STUDENT_HANDLER_CURRENT_REQUEST,
    BASIC_INPUT_STUDENTS_REQUEST,
    GET_ATTACHMENTS_TO_REQUEST,
    DELETE_REQUEST_ATTACHMENT,
    ADD_CHILDREN_TO_REQUEST,
    DELETE_CHILDREN_FROM_REQUEST,
    GET_CLAIMANT_STATUSES,
    GET_PARENTAL_STATUS,
    ADD_PARENT_TO_REQUEST,
    ADD_INPUT_TO_ARRAY_IN_REQUEST,
    ADD_SINGLE_ATTACHMENT,
    GET_LIST_OF_CHILDREN,
    GET_INFO_OF_CHILD,
    GET_ORDER_OF_CHILD,
    GET_GENDER_FOR_CHILD,
    ADD_WORKER_TO_IMPORTANT_TASK,
    DELETE_WORKER_FROM_IMPORTANT_TASK,
    SELECT_WORKER_FROM_EMPLOYEE_LIST,
    ADD_AMOUNT_TO_WORKER_IMPORTANT_TASK,
    ADD_WORKERS_WHITH_AMMOUNT,
    ADD_PEOPLE_HANDLER_CURRENT_REQUEST,
    DELETE_PEOPLE_HANDLER_CURRENT_REQUEST,
    GET_COMPENSATION_TYPES,
} from "../../enums/Request/request.types";
import {
    // DROPDOWN_SELECT,
    GET_ALL_DOCUMENTS, UNSET_CURRENT_DOCUMENT,
} from "../../enums/Documents/DocumentsType";
import {
    ADD_BASIC_INPUT_HANDLER_CURRENT_REQUEST,
    ADD_COMMENT_HANDLER_CURRENT_REQUEST, ADD_OBJ_KEY_YO_ARRAY,
    ADD_PERIOD_HANDLER_CURRENT_REQUEST, ATTACHMENTS_VALIDATION,
    CALENDAR_DATE_PICK,
    CHANGE_CHECKED_STATE,
    DATEPICKER_SELECT_CURRENT_REQUEST,
    DELETE_PERIOD_HANDLER_CURRENT_REQUEST,
    DROPDOWN_SELECT_CURRENT_REQUEST,
    DROPDOWN_SELECT_FOR_ARRAY_CURRENT_REQUEST,
    EMPTY_CURRENT_REQUEST, INPUT_TEXT_AREA_CONTENT, INPUT_VALIDATION_IN_ARRAY, REMOVE_OBJ_FROM_ARRAY,
    SET_CURRENT_REQUEST_FROM_CLASS, SET_STRUCT_ID_IMPORTANT_TASK,
    VALIDATION_CONTROL_CURRENT_REQUEST,
} from "../../enums/Request/currentRequestControls.types";


import {
    getCompensationTypesAction,
} from "../../actions/Requests/RequestsFromLeader/RecruitmentToWork/getCompensationTypes.action";

import moment from "moment";

export const requestReducer = (state = initialState, action) => {
    switch (action.type) {

    // IMPORTANT TASK
    case SELECT_DROPDOWN_MENU_ITEM:
        const [event, item, stateField] = action.payload;
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [stateField]: item.id,
                isValid: {
                    ...state.currentRequest.isValid,
                    [stateField]: true,
                },
            },
        };
    case ADD_AMOUNT_TO_WORKER_IMPORTANT_TASK:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                workers: state.currentRequest.workers.map((employee, employeeIndex) => {
                    if (employeeIndex === action.payload.index) {
                        return { ...employee, amount: action.payload.amount };
                    } else {
                        return employee;
                    }
                }),
            },
        };
    case SELECT_WORKER_FROM_EMPLOYEE_LIST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                workers: state.currentRequest.workers.map((employee, employeeIndex) => {
                    if (employeeIndex === action.payload.index) {
                        return { ...employee, pid: action.payload.pid };
                    } else {
                        return employee;
                    }
                }),
            },
        };
    case ADD_WORKER_TO_IMPORTANT_TASK:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                workers: [...state.currentRequest.workers, { pid: null, amount: 0 }],
            },
        };
    case ADD_WORKERS_WHITH_AMMOUNT:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                workers: action.payload,
            },
        };
    case DELETE_WORKER_FROM_IMPORTANT_TASK:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                workers: state.currentRequest.workers.filter((e, i) => i !== action.payload),
            },
        };
    case GET_EMPLOYEE_BY_STRUCT_ID:
        return {
            ...state,
            employeeListByStructId: action.payload,
        };
    case CLEAR_EMPLOYEE_BY_STRUCT_ID:
        const { employeeListByStructId } = state;
        if (employeeListByStructId) {
            const temp = state;
            delete temp.employeeListByStructId;
            return temp;
        }
        return state;
    case SET_SELECTED_STRUCT_ITEM:
        return {
            ...state,
            selectedStructItem: action.payload,
            currentRequest: {
                ...state.currentRequest,
                structId: action.payload.id,
                isValid: {
                    ...state.currentRequest.isValid,
                    structId: true,
                },
                workers: [{ pid: null, amount: 0 }],
            },
        };
    case CLEAR_SELECTED_STRUCT_ITEM:
        const { selectedStructItem } = state;
        if (selectedStructItem) {
            const temp = state;
            delete temp.selectedStructItem;
            return temp;
        }
        return state;

    case INPUT_TEXT_AREA_CONTENT:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                isValid: {
                    ...state.currentRequest.isValid,
                    [action.payload.field]: true,
                },
                [action.payload.field]: action.payload.value,
            },
        };
    case SET_STRUCT_ID_IMPORTANT_TASK:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                structId: action.payload,
            },
        };

        // LEADER REQUESTS
        // START
    case GET_FUNCTION_MARKERS:
        return {
            ...state,
            functionMarkers: action.payload,
        };
    case CLEAR_FUNCTION_MARKERS:
        const { functionMarkers } = state;
        if (functionMarkers) {
            const temp = state;
            delete temp.functionMarkers;
            return temp;
        }
        return state;
    case GET_STRUCT_TOP:
        return {
            ...state,
            structTop: action.payload.map(struct => {
                return {
                    id: struct.firmId,
                    name: struct.name,
                };
            }),
        };
    case CLEAR_STRUCT_TOP:
        const { structTop } = state;
        if (structTop) {
            const temp = state;
            delete temp.structTop;
            return temp;
        }
        return state;
    case GET_STRUCT_TREE:
        return {
            ...state,
            structTree: action.payload,
        };
    case CLEAR_STRUCT_TREE:
        const { structTree } = state;
        if (structTree) {
            const temp = state;
            delete temp.structTree;
            return temp;
        }
        return state;
    case EDIT_STRUCTURE_ITEM:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                structName: "",
            },
        };
        // END
        // CURRENT REQUEST CONTROLS
        // START
    case EMPTY_CURRENT_REQUEST:
        return { ...state, currentRequest: action.payload };
        // TODO REFACTOR SHIFT DAYS DROPDOWN SELECTORS
    case DROPDOWN_SELECT_STUDY_LEAVE_REQUEST:
        const { el, stateName } = action.payload;
        const validKey = { ...state.currentRequest.isValid };
        for (const key in validKey) {
            validKey[`${key}`] = true;
        }
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                isValid: validKey,
                [`${stateName}`]: el.id,
            },
        };
    case DROPDOWN_SELECT_UNPAID_PRACTICE:
        const { elem, stateNameUnpaid } = action.payload;
        const validKeyUnpaid = { ...state.currentRequest.isValid };

        for (const key in validKeyUnpaid) {
            validKeyUnpaid[`${key}`] = true;
        }
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                isValid: validKeyUnpaid,
                [`${stateNameUnpaid}`]: elem.id,
            },
        };
    case DROPDOWN_SELECT_CURRENT_REQUEST:
        const {
            // event,
            element,
            stateProperty,
            arrField,
            indexOfArray,
        } = action.payload;
        const validKeys = { ...state.currentRequest.isValid };

        // TODO REFACTOR
        if (validKeys.bloodDonationData) {
            for (const key in validKeys) {
                if (validKeys[key].constructor.name === "Object") {
                    for (const innerKeys in validKeys[key]) {
                        validKeys[key][innerKeys] = true;
                    }
                } else {
                    validKeys[`${key}`] = true;
                }
            }
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    [`${stateProperty}`]: element.id,
                    isValid: validKeys,
                },
            };
        }
        for (const key in validKeys) {
            validKeys[`${key}`] = true;
        }
        if (stateProperty === "shift") {
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    // leaveRequestDwhId: element.reason,
                    leaveRequestDwhId: element.reason,
                    dateFrom: element.dateFrom,
                    dateTo: element.dateTo,
                    isValid: validKeys,
                    // [`${stateProperty}`]: element.id
                },
            };
            //TODO POLINA REFACTOR
        } else if (stateProperty === "disabilityDocumentId") {
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    disabilityFrom: element.from,
                    disabilityTo: element.to,
                    isValid: validKeys,
                    disabilityDocumentName: element.name,
                    [`${stateProperty}`]: element.id,
                },
            };

        } else if (
            stateProperty === "relativeId" ||
                stateProperty === "isSingleParent" ||
                stateProperty === "parentsAlrosaStatusId" ||
                stateProperty === "leaveReasonId"
        ) {
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    [`${stateProperty}`]: element.id,
                    isValid: validKeys,
                },
            };
        } else if (arrField) {
            const newArr = [...state.currentRequest[arrField]];
            newArr[indexOfArray] = {
                ...newArr[indexOfArray],
                [`${stateProperty}`]: element.id,
                [`${stateProperty}Valid`]: true,
            };

            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    [`${arrField}`]: newArr,
                },
            };
        } else {
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    dateFrom: element.dateFrom,
                    dateTo: element.dateTo,
                    isValid: validKeys,
                    [`${stateProperty}`]: element.id,
                },
            };
        }
    case DROPDOWN_SELECT_FOR_ARRAY_CURRENT_REQUEST:
        const { array, stateproperty } = action.payload;
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [`${stateproperty}`]: array,
            },
        };

    case CHANGE_CHECKED_STATE:
        const temp = state.dismissalReasons.map((e) => {
            if (e.id === action.payload.id) {
                return { ...e, checked: !action.payload.checked };
            } else {
                return e;
            }
        });
        return {
            ...state,
            dismissalReasons: temp,
        };

    case DATEPICKER_SELECT_CURRENT_REQUEST:
        const { date, index, field } = action.payload;
        const periods = [...state.currentRequest.periods];
        periods[index][field] = moment(date).format("YYYY-MM-DD");
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                periods,
                isValid: {
                    ...state.currentRequest.isValid,
                    validDate: true,
                },
            },
        };
    case ADD_PERIOD_HANDLER_CURRENT_REQUEST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                periods: [
                    ...state.currentRequest.periods,
                    { dateFrom: null, dateTo: null },
                ],
            },
        };
    case DELETE_PERIOD_HANDLER_CURRENT_REQUEST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                periods: [...state.currentRequest.periods].filter(
                    (e, i) => i !== action.payload,
                ),
            },
        };
    case ADD_COMMENT_HANDLER_CURRENT_REQUEST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                employeeComment: action.payload,
            },
        };
    case ADD_BASIC_INPUT_HANDLER_CURRENT_REQUEST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [`${action.payload.field}`]: action.payload.value,
            },
        };


    case VALIDATION_CONTROL_CURRENT_REQUEST:
        switch (action.payload.flag) {
        case "string":
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    isValid: {
                        ...state.currentRequest.isValid,
                        [`${action.payload.key}`]: action.payload.value,
                    },
                },
            };
        case "object": {
            const {
                object,
                // innerKey,
                // value
            } = action.payload;
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    isValid: {
                        ...state.currentRequest.isValid,
                        [`${action.payload.object}`]: {
                            ...state.currentRequest.isValid[object],
                            [`${action.payload.innerKey}`]:
                                    action.payload.value,
                        },
                    },
                },
            };
        }
        default:
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    isValid: {
                        ...state.currentRequest.isValid,
                        [`${action.payload.key}`]: action.payload.value,
                    },
                },
            };
        }
    case SET_CURRENT_REQUEST_FROM_CLASS:
        return {
            ...state,
            currentRequest: action.payload,
        };
    case CALENDAR_DATE_PICK:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [`${action.payload.field}`]: action.payload.date,
            },
        };
    case ADD_BLOOD_DONATION_INFORMATION:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                bloodDonationData: {
                    ...state.currentRequest.bloodDonationData,
                    [`${action.payload.field}`]: action.payload.value,
                },
                isValid: {
                    ...state.currentRequest.isValid,
                    bloodDonationData: {
                        ...state.currentRequest.isValid.bloodDonationData,
                        [`${action.payload.field}`]: true,
                    },
                },
            },
        };
        // }

    case ADD_EMPTY_STRING:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                obtainingDocumentAddress: action.payload,
            },
        };

        // END
        // shift days
    case GET_RESCHEDULE_SHIFT_DAYS:
        return { ...state, shiftDays: action.payload };
    case CLEAR_SHIFT_DAYS:
        const tempState = { ...state };
        if (tempState.shiftDays) {
            delete tempState.shiftDays;
        }
        return tempState;
        //UnpaidPractice leader request
    case GET_INTERNSHIPS_TYPES:
        return { ...state, internshipsTypes: action.payload };
    case GET_INTERNSHIPS_STRUCTS:
        return { ...state, internshipsStructs: action.payload };
    case GET_INTERNSHIPS_STRUCTS_EMPLOYEES:
        return { ...state, internshipsEmployees: action.payload };
    case ADD_PEOPLE_HANDLER_CURRENT_REQUEST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [`${action.payload.stateName}`]: [
                    ...state.currentRequest[`${action.payload.stateName}`],
                    action.payload.data,
                ],
            },
        };
    case INPUT_VALIDATION_IN_ARRAY:
        const { fieldElement, bool, indexArray, arrayField } = action.payload;
        const tempArray = [...state.currentRequest[arrayField]];
        tempArray[indexArray] = {
            ...state.currentRequest[arrayField][indexArray],
            [`${fieldElement}`]: bool,
        };
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [arrayField]: tempArray,
                // state.currentRequest.students[indexArray] = {
                //     ...state.currentRequest.students[indexArray],
                //     [`${fieldElement}`]: bool,
                // },
            },
        };
    case ATTACHMENTS_VALIDATION:
        const { arrIndex, attachIndex, falsyAttach, arrayName, calledArray, fieldValidation } = action.payload;
        const validatedStudent = {
            ...state.currentRequest[`${arrayName}`][arrIndex],
        };
        validatedStudent[calledArray][attachIndex] = {
            ...validatedStudent[calledArray][attachIndex],
            [fieldValidation]: falsyAttach,
        };
        var arrayOfStudents = [...state.currentRequest[`${arrayName}`]];
        arrayOfStudents[arrIndex] = validatedStudent;
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [`${arrayName}`]: arrayOfStudents,
            },
        };
    case DELETE_PEOPLE_HANDLER_CURRENT_REQUEST:
        console.log(...state.currentRequest[`${action.payload.stateName}`]);
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [`${action.payload.stateName}`]: [...state.currentRequest[`${action.payload.stateName}`]].filter(
                    (e, i) => i !== action.payload.index,
                ),
            },
        };
    case BASIC_INPUT_STUDENTS_REQUEST:
        const { input, i, inputField } = action.payload;
        const students = [...state.currentRequest.students];

        students[i] = { ...students[i], [inputField]: input, "fullNameValid": "" };
        // students[i] = delete students[i]["fullNameValid"];
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,

                students,
                // isValid: {
                //     ...state.currentRequest.isValid,
                // },
            },
        };

        //UnpaidPractice leader request end

        //ParentalLeave request start
    case ADD_CHILDREN_TO_REQUEST:
        if (state.currentRequest.children.length < 5) {
            const array = [...state.currentRequest.children];
            array.push({
                tittle: "Выберите ребенка ниже",
                id: null,
                externalId: null,
                birthDate: null,
                firstName: null,
                lastName: null,
                middleName: " ",
                genderId: null,
                birthCertificateNumber: null,
                birthCertificateDate: null,
                snils: null,
                orderId: null,
                attachments: [
                    // {
                    //     uid: null,
                    //     type: null,
                    // },
                ],
            });
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    children: array,
                },
            };
        } else {
            return {
                ...state,
            };
        }
        // break;

    case DELETE_CHILDREN_FROM_REQUEST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                children: [...state.currentRequest.children].filter(
                    (e, i) => i !== action.payload,
                ),
            },
        };
    case GET_CLAIMANT_STATUSES:
        return {
            ...state,
            claimantStatuses: action.payload,
        };
    case GET_PARENTAL_STATUS:
        return {
            ...state,
            parentsAlrosaStatusId: action.payload,
        };
    case ADD_PARENT_TO_REQUEST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                parents: action.payload,
            },
        };
    case ADD_INPUT_TO_ARRAY_IN_REQUEST:
        const { parentInput, indexOfParent, inputfield, isParent } = action.payload;
        let parents;
        if (isParent) {
            parents = [...state.currentRequest.parents];
            parents[indexOfParent] = {
                ...parents[indexOfParent],
                [inputfield]: parentInput,
                [`${inputfield}Valid`]: "",
            };
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    parents,

                },
            };
        } else {
            parents = [...state.currentRequest.children];
            parents[indexOfParent] = {
                ...parents[indexOfParent],
                [inputfield]: parentInput,
                [`${inputfield}Valid`]: "",
            };
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    children: parents,

                },
            };
        }

    case GET_LIST_OF_CHILDREN:
        return {
            ...state,
            children: action.payload,
        };
    case GET_INFO_OF_CHILD:
        const { child, childIndex, childField } = action.payload;
        const childrenArray = [...state.currentRequest.children];
        if (childField === "child") {
            childrenArray[childIndex] = {
                ...child,
                attachments: [
                    //   {
                    //     uid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    //     type: 1,
                    // }
                ],
                // snils: "2023-11-10T06:05:53.576Z",
                // tittle: `${child.lastName ? child.lastName : ""} ${child.firstName ? child.firstName : "Выберите ребенка ниже"}`,
            };
        } else {
            childrenArray[childIndex] = {
                ...childrenArray[childIndex],
                [`${childField}`]: child.id,
            };
        }
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                children: childrenArray,

            },
        };
    case  GET_ORDER_OF_CHILD:
        return { ...state, order: action.payload };
    case GET_GENDER_FOR_CHILD:
        return { ...state, gender: action.payload };
        //ParentalLeave request end
        //RecruitmentToWorkOnHolidays request start
    case GET_SUBORDINATES:
        return { ...state, subordinates: action.payload };
    case  GET_COMPENSATION_TYPES:
        return { ...state, compensationTypes: action.payload };
        // RecruitmentToWorkOnHolidays request end
        //MaterinityLeave
    case GET_MATERINITY_DIS_DOCS:
        return { ...state, disabilityDocs: action.payload };
        //StudyLeave
    case GET_LEAVE_TYPES:
        return { ...state, types: action.payload };
    case GET_REASONS_TYPES:
        return { ...state, reasons: action.payload };
    case GET_EDUCATIONAL_PROGRAMS:
        return { ...state, educationalPrograms: action.payload };
    case GET_COURSE_NUMBERS:
        return { ...state, courseNumbers: action.payload };
    case GET_ALL_REQUESTS:
        return { ...state, userRequests: action.payload };
    case DELETE_ONE_REQUEST:
        const filteredRequests = state.userRequests.filter(
            (e) => e.uid !== action.payload,
        );
        return { ...state, userRequests: filteredRequests };
    case GET_CURRENT_REQUEST:
        return { ...state, currentRequest: action.payload };
    case CLEAR_CURRENT_REQUEST:
        const requests = { ...state };
        requests.currentRequest && delete requests.currentRequest;
        return requests;
        // TODO POLINA REFACTOR
        // START
    case SET_CURRENT_REQUEST:
        return { ...state, currentRequest: action.payload };
        // TODO polina
    case SET_VALIDATED_REQUEST:
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                validatedData: action.payload,
            },
        };
    case "SET_CURRENT_DOCUMENT":
        return { ...state, singleDocument: action.payload };

    case UNSET_CURRENT_DOCUMENT:
        return { ...state, singleDocument: null };
        //
    case UNSET_CURRENT_REQUEST:
        return { ...state, currentRequest: action.payload };
        // END
    case GET_ATTACHMENTS:
        return { ...state, requestAttachments: action.payload };
    case GET_ATTACHMENTS_TO_REQUEST:
        const { ind, doc, indexAttach, attachField } = action.payload;
        const newElement = {
            ...state.currentRequest[`${attachField}`][ind],
        };
        newElement.attachments[indexAttach] = { ...doc, attachValidation: "" };
        var arrayStudents = [...state.currentRequest[`${attachField}`]];
        arrayStudents[ind] = newElement;
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [`${attachField}`]: arrayStudents,
            },
        };
    case ADD_OBJ_KEY_YO_ARRAY:
        const { arrayKey, objKey, objKeyName, fieldArray, indexInArray, elementIndex } = action.payload;

        const mainElement = {
            ...state.currentRequest[`${fieldArray}`][elementIndex],
        };
        if (arrayKey === null) {
            mainElement[objKeyName] = objKey;
            const arrayOfObj = [...state.currentRequest[`${fieldArray}`]];
            arrayOfObj[elementIndex] = mainElement;
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    [`${fieldArray}`]: arrayOfObj,
                },
            };

        } else {
            mainElement[arrayKey][indexInArray] = {
                ...mainElement[arrayKey][indexInArray],
                [objKeyName]: objKey,
                [`${objKeyName}Validation`]: "",
            };
            const arrayOfObj = [...state.currentRequest[`${fieldArray}`]];
            arrayOfObj[elementIndex] = mainElement;

            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    [`${fieldArray}`]: arrayOfObj,
                },
            };
        }



        // const filteredAttach = state.currentRequest[`${attachArray}`][personIndex].attachments.filter((el) => el.uid !== uid);
        // const filteredObj = {
        //     ...state.currentRequest[`${attachArray}`][personIndex],
        //     attachments: filteredAttach,
        // };
        // const filteredArray = [...state.currentRequest[`${attachArray}`]];
        // filteredArray[personIndex] = filteredObj;
        // return {
        //     ...state,
        //     currentRequest: {
        //         ...state.currentRequest,
        //         [`${attachArray}`]: filteredArray,
        //         // [`${attachArray}`]: [...[`${attachArray}`], [`${attachArray}`][personIndex].attachments = filteredAttach],
        //
        //     },
        // };
    case REMOVE_OBJ_FROM_ARRAY:
        const { arrayKeyRemove, indexInArrayRemove, elementIndexRemove, fieldArrayRemove } = action.payload;
        const filteredArr = state.currentRequest[`${fieldArrayRemove}`][elementIndexRemove][arrayKeyRemove].filter((el, index) => index !== indexInArrayRemove);
        const Obj = {
            ...state.currentRequest[`${fieldArrayRemove}`][elementIndexRemove],
            [arrayKeyRemove]: filteredArr,
        };
        const filterArr = [...state.currentRequest[`${fieldArrayRemove}`]];
        filterArr[elementIndexRemove] = Obj;
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                [`${fieldArrayRemove}`]: filterArr,
            },
        };

    case ADD_SINGLE_ATTACHMENT:
        const { indexOfAttach, document, singleUpload } = action.payload;
        if (singleUpload === true) {
            const tempArray = [...state.currentRequest.attachments];
            tempArray[indexOfAttach] = document;
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    attachments: tempArray,
                },
            };
        } else {
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    attachments:
                            [...state.currentRequest.attachments, document],
                },
            };
        }


    case DELETE_REQUEST_ATTACHMENT:
        const { uid, personIndex, attachArray } = action.payload;
        if (typeof (personIndex) === "number") {
            const filteredAttach = state.currentRequest[`${attachArray}`][personIndex].attachments.filter((el) => el.uid !== uid);
            const filteredObj = {
                ...state.currentRequest[`${attachArray}`][personIndex],
                attachments: filteredAttach,
            };
            const filteredArray = [...state.currentRequest[`${attachArray}`]];
            filteredArray[personIndex] = filteredObj;
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    [`${attachArray}`]: filteredArray,
                    // [`${attachArray}`]: [...[`${attachArray}`], [`${attachArray}`][personIndex].attachments = filteredAttach],

                },
            };
        } else {
            const filteredAttach = [...state.currentRequest.attachments].filter((el) => el.uid !== uid);
            return {
                ...state,
                currentRequest: {
                    ...state.currentRequest,
                    attachments: filteredAttach,
                },
            };
        }

    case CLEAR_ATTACHMENTS:
        return { ...state, requestAttachments: [] };
    case DELETE_ATTACHMENT:
        const filteredAttachments = state.requestAttachments.filter(
            (e) => e.id !== action.payload,
        );
        return { ...state, requestAttachments: filteredAttachments };

    case GET_APPLICATION_FILE:
        return { ...state, application: action.payload };
    case DELETE_APPLICATION_FILE:
        return { ...state, application: [] };
    case GET_REQUESTS_RESCHEDULE:
        return { ...state, requestsToReschedule: action.payload };
        // TODO POLINA REFACTOR
        // START
    case GET_ALL_HR_DOCS:
        return { ...state, hrDocuments: action.payload };

    case GET_CURRENT_HR_DOCS:
        return { ...state, currentHrDocument: action.payload };
    case UNSET_CURRENT_HR_DOCS:
        return { ...state, currentHrDocument: action.payload };

    case GET_LEADER_REQUESTS:
        return { ...state, leaderRequests: action.payload };
        //Справки(References)
    case GET_ALL_REFERENCES:
        return { ...state, userReferences: action.payload };

    case GET_ALL_DOCUMENTS:
        return { ...state, allDocuments: action.payload };
        //Layoff RequestPage
    case GET_PLANNED_REVOCATION:
        return { ...state, plannedDismissal: action.payload };
    case GET_DOCUMENT_OBTAINING:
        return { ...state, documentObtaining: action.payload };
    case GET_DOCUMENT_REASONS:
        return { ...state, dismissalReasons: action.payload };
    case GET_LAYOFF_ADRESS:
        return { ...state, dismissalAdress: action.payload };
    case CREATE_SIGN_REQUEST:
        return { ...state, createSignRequest: action.payload };
        // END
    default:
        return state;
    }
};
