import moment from "moment";

class RequestsHelper {
    static postponeDropdownFabric(rawSchedule) {
        // from {
        //         id: 1829852,
        //         from: '2022-12-12T00:00:00',
        //         to: '2022-12-25T00:00:00',
        //         days: 14
        //       }
        // to {
        //  id: 1829852,
        //  name: 12.12.2022 - 25.12.2022 / 14 календарных дней
        // }
        if (rawSchedule && rawSchedule.length >= 1) {
            return rawSchedule.map(element => {
                return {
                    id: element.id,
                    name: `
                    ${moment(element.from).format("DD.MM.YYYY")}
                    - 
                    ${moment(element.to).format("DD.MM.YYYY")}
                    /
                    ${element.days} календарных дней`,
                    dateFrom: element.from,
                    dateTo: element.to,
                };
            });
        } else {
            return false;
        }
    }

    static datepickerMinDateChecker(field, period, state) {
        switch (field) {
        case "dateFrom":
            return new Date(moment().add(5, "day"));
        case "dateTo":
            return period.dateFrom
                ? new Date(moment(period.dateFrom))
                : new Date(moment().add(5, "day"));
        default:
            return new Date();
        }
    }

    static datepickerMaxDateChecker(field, period) {
        switch (field) {
        case "dateFrom":
            return period.dateTo === null
                ? null
                : new Date(moment(period.dateTo));
        case "dateTo":
            return null;
        default:
            return null;
        }
    }

    static getUniqueFromArray(array) {
        console.log(array);
        console.log([...array.reduce((map, { code, block, message }) => {
            return (map.set(`${code}-${block}-${message}`, { code, block, message }));
        }, new Map()).values()]);
        return [...array.reduce((map, { code, block, message }) => {
            return (map.set(`${code}-${block}-${message}`, { code, block, message }));
        }, new Map()).values()];
    }


}

export default RequestsHelper;
