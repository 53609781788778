import { $api } from "../../../../../services/request.config";

export const sendMemoThunkAction = (data) => async (dispatch) => {
    const { uid, employees, props } = data;
    console.log(data);
    try {
        const response = await $api.post(`/v1/managers/requests/rvd/applications/${uid}/memo`, { pids: employees });
        console.log("DAAAAATTTAAA", response.data);
    } catch (e) {

    }
};
