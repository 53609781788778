import { isTerminal } from "../../../../../../../constants";
import {
    getReasonBelongsTypeThunkAction,
} from "../../../../../../../redux/actions/Requests/LeaveMetadata/thunk/getReasonBelongsType.thunk.action";
import ExtraVacationDaysApplication from "../../../../../../../frontend.classes/Requests/ExtraVacationDays.application";
import DatePopupSelector from "../../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";
import DatePeriodControlled from "../../../../../../common/Inputs/DatePeriodControlled/DatePeriodControlled";
import CommentInput from "../../../../../../common/Inputs/CommentInput/CommentInput";
import CustomUploader from "../../../../../../common/CustomUploader/CustomUploader";
import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import {
    clearAttachmentsAction,
} from "../../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    getRequestsForRescheduleThunkAction,
} from "../../../../../../../redux/actions/Requests/Postpone/thunk/getRequestsForReschedule.thunk.action";
import {
    emptyCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import NotificationWindowRequests from "../../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import {
    addBloodDonationInformationCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addBloodDonationInformation.currentRequest";
import SingleUploader from "../../../../../../common/SingleUploader/SingleUploader";
import {
    validationControlCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    deleteAllAttachmentThunkAction,
} from "../../../../../../../redux/actions/Requests/Attachments/thunk/deleteAllAttachment.thunk.action";
import { ScrollButton } from "../../../../../../common/componentsTerminal/ScrollButton/ScrollButton";

import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

isTerminal ? import("./ExtraVacationDaysTerminal.scss") : import("./ExtraVacationDays.scss");

const ExtraVacationDays = (
    {
        setRequestStatus,
        requestStatus,
        requestOption,
        setRequestState,
    },
) => {

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const reasons = useSelector(state => state.requests.reasons);
    const currentRequest = useSelector(state => state.requests.currentRequest);

    const [notificationWindow, setNotificationWindow] = useState(false);

    function selectDropdown(event, element, stateProperty) {
        if (currentRequest && currentRequest.attachments) {
            dispatch(deleteAllAttachmentThunkAction(currentRequest.attachments));
        } else {
            dispatch(deleteAllAttachmentThunkAction([]));
        }
        dispatch(dropdownSelectionCurrentRequest({ event, element, stateProperty }));
    }

    function commentHandler(e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }


    function changeDateHandler(field, date) {
        dispatch(validationControlCurrentRequest(field, true));
        const value = moment(date).format("YYYY-MM-DD");
        dispatch(addBloodDonationInformationCurrentRequest({ field: field, value: value }));
    }

    function referenceHandler(e) {
        dispatch(validationControlCurrentRequest("certificateNumber", true));
        dispatch(addBloodDonationInformationCurrentRequest({ field: "certificateNumber", value: e.target.value }));
    }

    useEffect(() => {
        setRequestStatus(false);
        dispatch(clearAttachmentsAction());
        // dispatch(getRequestsForRescheduleThunkAction());
        dispatch(getReasonBelongsTypeThunkAction(requestOption));
        if (!queryId) {
            dispatch(emptyCurrentRequest(new ExtraVacationDaysApplication({
                leaveTypeId: 9,
                periods: [{ dateFrom: null, dateTo: null }],
            })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);


    return (
        <section className={"extra-vacation-days"}>
            {isTerminal && <ScrollButton />}
            <DropdownMenu
                currentRequest={currentRequest}
                styleName={"reason"}
                label={<>Выберите причину предоставления дней отдыха <span className="required_text"> *</span></>}
                listOfOptions={reasons}
                stateProperty={"leaveReasonId"}
                helper={currentRequest && currentRequest.leaveReasonId === 34 ? "Для ухода за ребенком-инвалидом один из родителей может оформить до 4-х дополнительных дней отдыха в месяц. Неиспользованные дни отдыха суммируются ежемесячно и могут быть взяты однократно в течение года в размере до 24 календарных дней." : null}
                isValidKey={
                    currentRequest
                    && currentRequest.isValid
                    && currentRequest.isValid.leaveReasonId
                        ? currentRequest.isValid.leaveReasonId
                        : false
                }
                selectOptionClick={selectDropdown} />

            {currentRequest && currentRequest.leaveReasonId === 33 &&
                (<>
                    <DatePopupSelector
                        field={"donationDate"}
                        label={<>Дата сдачи крови <span className="required_text"> *</span></>}
                        errorMessage={
                            currentRequest
                            && currentRequest.isValid
                            && currentRequest.isValid.bloodDonationData
                            && currentRequest.isValid.bloodDonationData.donationDate
                                ? ""
                                : "Обязательное поле"
                        }
                        changeHandler={changeDateHandler}
                        propsDate={
                            currentRequest
                            && currentRequest.bloodDonationData
                            && currentRequest.bloodDonationData.donationDate
                        }
                        minDate={new Date(moment().subtract(1, "years").toDate())}
                        maxDate={new Date()}
                    />
                </>)
            }

            {currentRequest &&
                currentRequest.periods &&
                currentRequest.periods.map((period, index) => {
                    return (
                        <DatePeriodControlled
                            setRequestState={setRequestState}
                            periodsLength={
                                currentRequest
                                && currentRequest.periods
                                && currentRequest.periods.length
                                    ? currentRequest.periods.length
                                    : false
                            }
                            key={uuidv4()}
                            parentStyle={"postpone-vacation"}
                            period={period}
                            index={index}
                            datesForRange={{
                                minFrom: new Date(),
                                // minTo: new Da ,
                            }}
                            label={
                                {
                                    from: <>Дата начала отдыха <span className="required_text"> *</span></>,
                                    to: <>Дата окончания отдыха <span className="required_text"> *</span></>,
                                }
                            }
                        />
                    );
                })
            }
            {
                currentRequest && currentRequest.leaveReasonId === 33 &&
                (
                    <>
                        <CommentInput
                            value={
                                currentRequest
                                && currentRequest.bloodDonationData
                                && currentRequest.bloodDonationData.certificateNumber
                            }
                            basicInput={true}
                            maxLength={50}
                            stateProperty={"attachmentDocumentsCodes"}
                            changeHandler={referenceHandler}
                            label={<>Номер справки о сдаче крови и ее компонентов <span
                                className="required_text"> *</span></>}
                            errorMessage={
                                currentRequest
                                && currentRequest.isValid
                                && currentRequest.isValid.bloodDonationData
                                && currentRequest.isValid.bloodDonationData.certificateNumber
                                    ? ""
                                    : "Обязательное поле"
                            }
                            parentStyle={"extra-vacation-days"} />
                    </>
                )

            }
            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.employeeComment}
                stateProperty={"employeeComment"}
                changeHandler={commentHandler}
                label={"Комментарий"}
                parentStyle={"unplanned-vacation"}
            />
            {
                currentRequest && currentRequest.leaveReasonId === 33 &&
                (
                    <>
                        <CustomUploader />
                    </>
                )}
            {
                !isTerminal && currentRequest && currentRequest.leaveReasonId === 34 &&
                (
                    <>
                        <SingleUploader
                            label={
                                "Копия свидетельства о рождении (усыновлении) ребенка (детей)"
                            }
                            subType={"BirthOrGuardianshipCertificate"}
                            subTypeNumber={2}
                        />
                        <SingleUploader
                            label={
                                "Справка с места работы (службы)" +
                                " отца (матери, обоих родителей)" +
                                " ребенка о том, что он (она, они)" +
                                " не использует отпуск по уходу за ребенком"
                            }
                            subType={"ParentalLeaveAvailable"}
                            subTypeNumber={5}
                        />
                        <SingleUploader
                            label={
                                "Справка, подтверждающая факт установления инвалидности," +
                                " выданная бюро (главным бюро, Федеральным бюро)" +
                                " медико-социальной экспертизы"}
                            subType={"DisabilityConfirmation"}
                            subTypeNumber={3}
                        />
                        <SingleUploader
                            label={
                                "Документ о месте жительства" +
                                " (пребывания или фактического проживания)" +
                                " ребенка-инвалида (например," +
                                " у 14-летнего ребенка это паспорт с отметкой о регистрации)"
                            }
                            subType={"PlaceResidenceConfirmation"}
                            subTypeNumber={4}
                        />
                    </>
                )
            }
            <div className={"extra-vacation-days__notification"}>
                Обращаем внимание: для закрытия заявки будет необходимо предоставить
                оригиналы документов в ОЦО (после получения соответствующего уведомления)
            </div>

            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
                blockingMessages={true}
            />
        </section>
    );
};

export default ExtraVacationDays;
