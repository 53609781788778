import { $api } from "../../../../../services/request.config";
import { getFoodCardBalanceAction, getMealCardBalanceAction } from "../getFoodCardBalance.action";

export const getMealCardBalanceThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/meal/card");
        if (response.status === 200) {
            console.log("FOOD BALANCE: ", response.data);
            dispatch(getMealCardBalanceAction(response.data));
        }
    } catch (error) {
        if (error.status === 404) {
            console.log("ОТСУТСТВУЕТ КАРТА ПИТАНИЯ у ПОЛЬЗОВАТЕЛЯ");
        }

    }
};
