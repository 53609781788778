export const EmployeesListCheckbox = ({ checked, onChange, disabled }) => {
    return (
        <>
            <input
                className="employer-checkbox"
                type="checkbox"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
        </>
    );
};