import {
    validationControlCurrentRequest,
} from "../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import { inputValidationInArray } from "../../redux/actions/Requests/currentRequestControls/inputValidationInArray";
import { attachmentsValidation } from "../../redux/actions/Requests/currentRequestControls/attachmentsValidation";

export default class RecruitmentToWorkOnHolidaysService {
    constructor() {
    }

    static validation(currentRequest, dispatch) {
        const keysToCheck = ["reason", "validRequest"];
        const temporaryRequest = { ...currentRequest };
        // employees: [
        //     {
        //         pid: null,
        //         days: [
        //             // {
        //             //     jobDate: null, // "2024-05-01",
        //             //     jobHours: null, // 8.5,
        //             //     compensationType: null, // 2,
        //             //     restDate: null, // "2024-05-04"
        //             // },
        //         ],
        //     },
        // ],
        currentRequest.employees && currentRequest.employees.forEach((employee, index) => {
            if (!employee.pid) {
                dispatch(
                    inputValidationInArray({
                        fieldElement: "pidValid",
                        bool: false,
                        indexArray: index,
                        arrayField: "employees",
                    }),
                );
                dispatch(validationControlCurrentRequest("validRequest", null));
                temporaryRequest["validRequest"] = null;
            }

            if (employee && employee.days.length > 0) {
                employee.days.forEach((day, dayIndex) => {
                    if (!day.jobHours) {
                        dispatch(attachmentsValidation({
                            arrIndex: index,
                            attachIndex: dayIndex,
                            falsyAttach: false,
                            arrayName: "employees",
                            calledArray: "days",
                            fieldValidation: "jobHoursValid",
                        }));
                        dispatch(validationControlCurrentRequest("validRequest", null));
                        temporaryRequest["validRequest"] = null;

                    }
                    if (!day.compensationType) {
                        dispatch(attachmentsValidation({
                            arrIndex: index,
                            attachIndex: dayIndex,
                            falsyAttach: false,
                            arrayName: "employees",
                            calledArray: "days",
                            fieldValidation: "compensationTypeValid",
                        }));
                        dispatch(validationControlCurrentRequest("validRequest", null));
                        temporaryRequest["validRequest"] = null;

                    }
                    if (day.compensationType && day.compensationType === 2) {
                        if (!day.restDate) {
                            dispatch(attachmentsValidation({
                                arrIndex: index,
                                attachIndex: dayIndex,
                                falsyAttach: false,
                                arrayName: "employees",
                                calledArray: "days",
                                fieldValidation: "restDateValid",
                            }));
                            dispatch(validationControlCurrentRequest("validRequest", null));
                            temporaryRequest["validRequest"] = null;

                        }

                    }
                    if (!day.compensationType || (day.compensationType && day.compensationType === 2 && !day.restDate) || !day.jobHours) {

                        dispatch(attachmentsValidation({
                            arrIndex: index,
                            attachIndex: dayIndex,
                            falsyAttach: true,
                            arrayName: "employees",
                            calledArray: "days",
                            fieldValidation: "fieldValid",
                        }));


                        dispatch(validationControlCurrentRequest("validRequest", null));
                        temporaryRequest["validRequest"] = null;

                    }
                });

            } else {
                dispatch(
                    inputValidationInArray({
                        fieldElement: "jobDateValid",
                        bool: true,
                        indexArray: index,
                        arrayField: "employees",
                    }));
                dispatch(validationControlCurrentRequest("validRequest", null));
                temporaryRequest["validRequest"] = null;
            }
        });
        return (
            this.keyChecker(
                keysToCheck,
                temporaryRequest,
                dispatch,
            )
        );
    }

    static keyChecker(arrayOfKeys, currentRequest, dispatch) {
        let result = 0;
        arrayOfKeys.forEach((e) => {
            if (
                !Array.isArray(e) &&
                (currentRequest[`${e}`] === null ||
                    currentRequest[`${e}`] === undefined || currentRequest[`${e}`] === "")
            ) {
                result += 1;
                dispatch(validationControlCurrentRequest(e, false));
            }
        });
        return !result;
    }
}
